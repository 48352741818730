<script setup lang="ts">
import { useAppHealth } from '~/stores/appHealth'

const appHealth = useAppHealth()
const isMounted = useMounted()

const { idle } = useIdle()

watch(idle, (isIdle, prevIsIdle) => {
  if (!isIdle && prevIsIdle) {
    appHealth.checkAuthWorkerStatus()
  }
})

const tenantConfig = useAppConfig().tenant

onMounted(() => {
  appHealth.checkAuthWorkerStatus()
})
</script>

<template>
  <div class="bg-surface min-h-screen w-full overflow-x-hidden">
    <div
      class="fixed inset-0 hidden h-screen w-full bg-gradient-to-b from-[#28B7F0]/100 to-[#35cdda]/[0.66] sm:block"
    />

    <div class="relative justify-between sm:mx-auto sm:flex sm:w-[1440px]">
      <ClientOnly v-if="isMounted">
        <Transition
          appear-from-class="opacity-0 translate-y-10 scale-90 motion-reduce:translate-y-0 motion-reduce:scale-100"
          appear
        >
          <div
            class="bg-surface z-10 w-full p-4 transition sm:ml-20 sm:mt-16 sm:min-h-[715px] sm:max-w-[30rem] sm:rounded-[10px] sm:p-12"
          >
            <LogoRialtic class="mb-12 block h-10" />
            <slot
              v-if="
                appHealth.authWorkerAvailable ||
                !tenantConfig.supportCognitoAuth
              "
            />

            <div v-else class="bg-error-light text-error-dark rounded-lg p-4">
              <p class="h3 mb-2">Authentication service unavailable.</p>
              <p>
                For assistance, contact
                <a
                  class="underline"
                  href="mailto:support@rialtic.io"
                  rel="noopener"
                  target="blank"
                >
                  support@rialtic.io
                </a>
              </p>
            </div>
          </div>
        </Transition>
      </ClientOnly>

      <div
        class="pointer-events-none relative hidden flex-1 p-8 text-white opacity-40 sm:block"
      >
        <ClientOnly>
          <Transition appear-from-class="opacity-0" appear>
            <LogoRialticIcon
              class="transition-duration-600 sm:max-w-2/3 lg:max-w-1/2 fixed w-full -translate-x-[24vw] transform transition lg:translate-x-[14vw]"
            />
          </Transition>
        </ClientOnly>
      </div>
    </div>
  </div>
</template>
